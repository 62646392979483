export enum MealTimeEnum {
  DINNER = 'dinner',
  LUNCH = 'lunch',
}

export enum DiscountDaysEnum {
  WEEKEND = 'Weekend',
  WEEKDAY = 'Weekday',
}

export enum DiscountTimingEnum {
  LUNCHTIMING = '6AM - 6PM',
  DINNERTIMING = '6PM - 6AM',
}

export enum CustomerDiscountTimingEnum {
  LUNCHTIMING = '6:00 AM - 6:00 PM',
  DINNERTIMING = '6:00 PM - 6:00 AM',
}

export enum CustomerDiscountTimingSlot {
  LUNCHTIMING = 'Lunch before 6PM',
  DINNERTIMING = 'Dinner after 6PM',
}

export enum CustomerDiscountTimingSlotStore {
  SHOP_BEFORE_EVENING = 'Shop before 6PM',
  SHOP_AFTER_EVENING = 'Shop after 6PM',
}
