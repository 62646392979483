import { get, merge } from 'lodash';
import { AppTheme } from '../../stores/commonStore';

export enum themeModes {
  light = 'light',
  dark = 'dark',
}
export const colors = {
  primary: {
    10: '#ffffff',
    20: '#dcdcde',
    30: '#F0F0F0',
    40: '#F3F3F3',
    50: '#e1e2e4',
    100: '#b3b3b6',
    200: '#8e8e90',
    300: '#767677',
    400: '#3c3c3d',
    500: '#121212',
  },
  background: {
    primary: {
      elevation: '#ffffff',
      base: '#ffffff',
    },
    secondary: {
      elevation: '#ffffff',
      base: '#F9F9F9',
    },
  },
  grey: {
    10: '#fafafa',
    20: '#DFDFDF',
    50: '#efefef',
    100: '#dddddd',
    200: '#c1c1c1',
    300: '#a3a3a3',
    400: '#8a8a8a',
    500: '#646464',
  },
  overlay: {
    500: 'rgba(33, 33, 33, 0.9)',
  },
  gold: {
    500: '#C8A35B',
    50: '#f8c405',
  },
  gradient: {
    0: 'rgba(85, 82, 84, 0.0)',
    50: 'rgba(0, 0, 0, 0.5)',
    60: 'rgba(50, 50, 52, 0.6)',
    70: 'rgba(0, 0, 0, 0.7)',
    80: 'rgba(0,0,0,0.8)',
    90: 'rgba(0,0,0,0.9)',
    100: 'rgba(0,0,0,0)',
  },
  singletone: {
    white: '#ffffff',
    black: '#000000',
  },
  backdrop: {
    500: '#121212',
  },
  opacity: {
    10: 'rgba(255, 255, 255, 0.1)',
    20: 'rgba(255, 255, 255, 0.2)',
    30: 'rgba(255, 255, 255, 0.3)',
    40: 'rgba(255, 255, 255, 0.4)',
    50: 'rgba(255, 255, 255, 0.5)',
    60: 'rgba(255, 255, 255, 0.6)',
    70: 'rgba(255, 255, 255, 0.7)',
    80: 'rgba(255, 255, 255, 0.8)',
    90: 'rgba(255, 255, 255, 0.9)',
    95: 'rgba(255, 255, 255, 0.95)',
    98: 'rgba(255, 255, 255, 0.98)',
  },
  error: {
    10: '#ffebea',
    50: '#ffd8d6',
    100: '#ffc4c1',
    200: '#ffb1ac',
    300: '#ff8983',
    400: '#ff6259',
    500: '#ff3b30',
  },
  success: {
    10: '#e6feeb',
    50: '#D3FADE',
    100: '#a3e9b4',
    200: '#77dd91',
    300: '#62de81',
    400: '#31d059',
    500: '#24ad47',
  },
  info: {
    10: '#e6f2ff',
    50: '#cce4ff',
    100: '#b3d7ff',
    200: '#99caff',
    300: '#66afff',
    400: '#3395ff',
    500: '#007aff',
  },
  warning: {
    10: '#FFF6EB',
    50: '#ffcc90',
    100: '#ffba6a',
    200: '#ffb258',
    300: '#ffaa46',
    400: '#ff9e2d',
    500: '#ff8e09',
  },
  static: {
    black: '#121212',
    white: '#ffffff',
    gold: '#f8c405',
  },
  modes: {
    [AppTheme.DARK]: {
      primary: {
        10: '#0d0d0d',
        20: '#2c2c2e',
        30: '#1E1E1E',
        40: '#1C1C1E',
        50: '#3a3a3c',
        100: '#535355',
        200: '#6d6d70',
        300: '#98989d',
        400: '#c0c0c5',
        500: '#ffffff',
      },
      background: {
        primary: {
          elevation: '#1c1c1e',
          base: '#121212',
        },
        secondary: {
          elevation: '#2a2a2a',
          base: '#1c1c1e',
        },
      },
      grey: {
        10: '#1d1d1d',
        20: '#3F3F3F',
        50: '#343232',
        100: '#535353',
        200: '#494949',
        300: '#717171',
        400: '#929292',
        500: '#a7a7a7',
      },
      singletone: {
        white: '#ffffff',
        black: '#000000',
      },
      backdrop: {
        500: 'rgb(33, 33, 33)',
      },
      overlay: {
        500: 'rgba(33, 33, 33, 0.9)',
      },
      gold: {
        50: '#C8A35B',
        500: '#f8c405',
      },
      gradient: {
        0: 'rgba(85, 82, 84, 0.0)',
        50: 'rgba(0, 0, 0, 0.5)',
        60: 'rgba(50, 50, 52, 0.6)',
        70: 'rgba(0, 0, 0, 0.7)',
        80: 'rgba(0,0,0,0.8)',
        90: 'rgba(0,0,0,0.9)',
        100: 'rgba(0,0,0,0)',
      },
      opacity: {
        10: 'rgba(18, 18, 18, 0.1)',
        20: 'rgba(18, 18, 18, 0.2)',
        30: 'rgba(18, 18, 18, 0.3)',
        40: 'rgba(18, 18, 18, 0.4)',
        50: 'rgba(18, 18, 18, 0.5)',
        60: 'rgba(18, 18, 18, 0.6)',
        70: 'rgba(18, 18, 18, 0.7)',
        80: 'rgba(18, 18, 18, 0.8)',
        90: 'rgba(18, 18, 18, 0.9)',
        95: 'rgba(18, 18, 18, 0.95)',
        98: 'rgba(18, 18, 18, 0.98)',
      },
      error: {
        10: '#400e0d',
        50: '#681a17',
        100: '#7c231f',
        200: '#972821',
        300: '#b6332b',
        400: '#f03930',
        500: '#ff5b52',
      },
      success: {
        10: '#07180c',
        50: '#082c12',
        100: '#0a461d',
        200: '#125e25',
        300: '#187b31',
        400: '#229b40',
        500: '#28c34e',
      },
      info: {
        10: '#00162f',
        50: '#001e42',
        100: '#002e62',
        200: '#004187',
        300: '#0058b7',
        400: '#0069dc',
        500: '#067dff',
      },
      warning: {
        10: '#5c3200',
        50: '#8c4e04',
        100: '#b16710',
        200: '#d87e16',
        300: '#ec8d1e',
        400: '#f99a2c',
        500: '#ffa63d',
      },
    },
  },
};

// ==========================================
// ==========================================

/* space to be used for paddings, margins, grids, positions */
export const space = {
  none: 0,
  xs: 2,
  sm: 4,
  md: 6,
  lg: 8,
  xl: 12,
  '1xl': 14,
  '2xl': 16,
  '3xl': 20,
  '4xl': 24,
  '5xl': 28,
  '6xl': 30,
  '7xl': 32,
  '8xl': 36,
  '9xl': 40,
};

// 10
space['sm+md'] = space.sm + space.md;
space['md+sm'] = space.sm + space.md;

// 22
space['3xl+xs'] = space['3xl'] + space.xs;
space['xs+3xl'] = space['3xl'] + space.xs;

// 48
space['lg+9xl'] = space.lg + space['9xl'];
space['9xl+lg'] = space['9xl'] + space.lg;

// 18
space['xs+2xl'] = space.xs + space['2xl'];
space['2xl+xs'] = space['2xl'] + space.xs;

// 50
space['6xl+3xl'] = space['6xl'] + space['3xl'];
space['3xl+6xl'] = space['6xl'] + space['3xl'];

// 58
space['5xl+6xl'] = space['5xl'] + space['6xl'];
space['6xl+5xl'] = space['5xl'] + space['6xl'];

// 60
space['3xl+9xl'] = space['3xl'] + space['9xl'];
space['9xl+3xl'] = space['3xl'] + space['9xl'];

// 64
space['9xl+4xl'] = space['9xl'] + space['4xl'];
space['4xl+9xl'] = space['9xl'] + space['4xl'];

// 68
space['9xl+5xl'] = space['9xl'] + space['5xl'];
space['5xl+9xl'] = space['9xl'] + space['5xl'];

// 72
space['8xl+8xl'] = 2 * space['8xl'];

// 80
space['9xl+9xl'] = space['9xl'] + space['9xl'];

// 88
space['9xl+9xl+lg'] = space['9xl'] + space['9xl'] + +space.lg;

// 100
space['9xl+9xl+3xl'] = space['9xl'] + space['9xl'] + space['3xl'];

// 120
space['9xl+9xl+9xl'] = space['9xl'] + space['9xl'] + space['9xl'];

// 60
space['9xl+3xl'] = space['9xl'] + space['3xl'];
space['3xl+9xl'] = space['9xl'] + space['3xl'];

// ==========================================

// ==========================================

/* fontSizes to be used  */
export const fontSizes = {
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '1xl': 22,
  '2xl': 24,
  '3xl': 28,
  '4xl': 32,
  '5xl': 36,
  '6xl': 40,
  '7xl': 48,
  '8xl': 56,
  '9xl': 64,
  '10xl': 72,
};

// ==========================================
// ==========================================

export const fonts = {
  heading: 'CircularStd-Medium',
  headingMedium: 'CircularStd-Medium',
  body: 'CircularStd-Regular',
  bodyMedium: 'CircularStd-Medium',
  mono: 'Gotham-Regular',
  monoMedium: 'Gotham-Medium',
  haeding: 'DomaineText-Regular',
  tag: 'UniversNextPro-Regular',
  quote: 'DomaineText-RegularItalic',
};

// ==========================================
// ==========================================

export const lineHeights = {
  '2xs': 16,
  xs: 18,
  sm: 22,
  md: 24,
  lg: 28,
  xl: 30,
  '1xl': 32,
  '2xl': 34,
  '3xl': 40,
  '4xl': 42,
  '5xl': 50,
  '6xl': 56,
  '7xl': 66,
  '8xl': 76,
  '9xl': 88,
  '10xl': 96,
};

// ==========================================
// ==========================================

export const letterSpacings = {
  '2xs': 0.25,
  xs: 0.25,
  sm: 0.25,
  md: 0.25,
  lg: 0.25,
  xl: 0.25,
  '1xl': 0.25,
  '2xl': 0.25,
  '3xl': 0.25,
  '4xl': 0.5,
  '5xl': 0.5,
  '6xl': 0.5,
  '7xl': 0.5,
  '8xl': 0.8,
  '9xl': 0.8,
  '10xl': 0.8,
};

// ==========================================
// ==========================================

export const sizes = {
  '6xs': 2,
  '5xs': 4,
  '4xs': 6,
  '3xs': 8,
  '2xs': 10,
  xs: 16,
  md: 24,
  lg: 32,
  xl: 40,
  '2xl': 48,
  '3xl': 56,
  '4xl': 64,
  '5xl': 72,
  '6xl': 80,
};

// 66
sizes['4xl+6xs'] = sizes['4xl'] + sizes['6xs'];
sizes['6xs+4xl'] = sizes['4xl'] + sizes['6xs'];

// ==========================================
// ==========================================

export const radii = {
  xs: 2,
  sm: 4,
  md: 8,
  lg: 12,
  xl: 16,
  '2xl': 24,
  '3xl': 32,
  '4xl': 40,
  '5xl': 48,
  full: 999,
};

// 20
radii['md+lg'] = radii.md + radii.lg;
radii['lg+md'] = radii.md + radii.lg;
radii['lg+sm'] = radii.lg + radii.sm;
radii['sm+lg'] = radii.lg + radii.sm;

// ==========================================
// ==========================================

export const borderWidths = {
  xs: 1,
  sm: 2,
  md: 4,
  lg: 6,
};

// ==========================================
// ==========================================

export const boxShadows = {
  // none: {
  //   shadowOffset: {
  //     width: 0,
  //     height: 0,
  //   },
  //   shadowOpacity: 0,
  //   shadowRadius: 0,
  //   elevation: 0,
  // },
  // sm: {
  //   shadowOffset: {
  //     width: 0,
  //     height: 1,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 1,
  //   elevation: 2,
  // },
  // md: {
  //   shadowOffset: {
  //     width: 0,
  //     height: 3,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 2,
  //   elevation: 6,
  // },
  // lg: {
  //   shadowOffset: {
  //     width: 0,
  //     height: 6,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 4,
  //   elevation: 12,
  // },
  // '2xl': {
  //   shadowOffset: {
  //     width: 0,
  //     height: 8,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 8,
  //   elevation: 16,
  // },
  // '3xl': {
  //   shadowOffset: {
  //     width: 0,
  //     height: 12,
  //   },
  //   shadowOpacity: 0.25,
  //   shadowRadius: 12,
  //   elevation: 24,
  // },
  none: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
  },
  sm: {
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.06,
    shadowRadius: 12,
    elevation: 3,
  },
  md: {
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 16,
    elevation: 4,
  },
  lg: {
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowOpacity: 0.1,
    shadowRadius: 20,
    elevation: 5,
  },
  '2xl': {
    shadowOffset: {
      width: 0,
      height: 8,
    },
    shadowOpacity: 0.15,
    shadowRadius: 24,
    elevation: 7,
  },
};

// ==========================================

const baseTheme = {
  initialColorModeName: AppTheme.LIGHT,
  colors,
  space,
  fonts,
  fontSizes,
  sizes,
  lineHeights,
  letterSpacings,
  borderWidths,
  radii,
  boxShadows,
};

export const getTheme = (mode: AppTheme | themeModes = AppTheme.LIGHT) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
    currentThemeMode: mode,
  });

export default baseTheme;
