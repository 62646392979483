/* eslint-disable no-nested-ternary */
import React from 'react';
import { variant, color, space, border } from 'styled-system';
import styled from 'styled-components/native';
import { Image } from 'react-native';
import StyledText from '../Typography/Text/Text';
import Pressable from '../../basic/Pressable/Pressable';
import StyledIcon from '../Icon/Icon';
import View from '../../basic/View/View';
import { ButtonPropTypes } from './ButtonPropType';
import {
  Appearance,
  Shape,
  State,
  Status,
  ThemeVariables,
} from '../../../../themes/new/helper';
import { TextCategories } from '../Typography/Text/helper';
import CustomLottieView from '../../custom/CustomLottieView';
import { radii } from '../../../../themes/new/theme';

const Icon = styled(StyledIcon).attrs(
  ({ buttonSize, iconSize, iconAppearance, state, status }) => ({
    size: iconSize || (buttonSize === 'xl' ? '2xl' : 'xl'),
    color:
      iconAppearance === Appearance.FILLED && state !== State.DISABLED
        ? `${status}.10`
        : iconAppearance !== Appearance.FILLED && state !== State.DISABLED
        ? `${status}.500`
        : iconAppearance === Appearance.FILLED && state === State.DISABLED
        ? 'primary.100'
        : 'primary.400',
  }),
)``;

const Text = styled(StyledText).attrs(({ textSize }) => ({
  size:
    textSize === 'md'
      ? 'sm'
      : textSize === 'sm'
      ? 'xs'
      : textSize === '10xl'
      ? '3xl'
      : textSize === 'tablet'
      ? '2xl'
      : 'md',
  weight: textSize === '10xl' ? 'medium' : 'regular',
}))`
  ${({ appearance, state, status, theme }) => ({
    color:
      appearance === Appearance.FILLED && state !== State.DISABLED
        ? theme.colors[status]['10']
        : appearance !== Appearance.FILLED && state !== State.DISABLED
        ? theme.colors[status]['500']
        : appearance === Appearance.FILLED && state === State.DISABLED
        ? theme.colors.primary['100']
        : theme.colors.primary['400'],
  })}
`;

const sizeVariants = ({ theme }) =>
  variant({
    prop: ThemeVariables.SIZE,
    variants: {
      sm: {
        paddingY: theme.space.sm,
        paddingX: 0,
      },
      md: {
        paddingY: theme.space.lg,
        paddingX: theme.space['2xl'],
      },
      lg: {
        paddingY: theme.space['sm+md'],
        paddingX: theme.space['2xl'],
      },
      xl: {
        paddingY: theme.space.xl,
        paddingX: theme.space['2xl'],
      },
      '10xl': {
        paddingY: theme.space['9xl'],
        paddingX: theme.space['9xl'],
      },
      tablet: {
        paddingY: theme.space['xs+2xl'],
        paddingX: theme.space['xs+2xl'],
      },
    },
  });

const appearanceVariants = ({ status, theme }) => {
  return variant({
    prop: ThemeVariables.APPEARANCE,
    variants: {
      filled: {
        bg: theme.colors[status]['500'],
        color: theme.colors[status]['10'],
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors[status]['500'],
      },
      outline: {
        bg: theme.colors.primary[10],
        color: theme.colors[status]['500'],
        borderWidth: theme.borderWidths.xs,
        borderColor: theme.colors[status]['500'],
      },
      ghost: {
        // bg: theme.colors.primary[10],
        color: theme.colors[status]['500'],
        borderWidth: theme.borderWidths.xs,
        borderColor: 'transparent',
        // paddingX: 0,
      },
    },
  });
};

const roundedVariants = ({ theme }) => {
  return variant({
    prop: Shape.ROUNDED,
    variants: {
      md: {
        borderRadius: theme.radii.md,
      },
      '4xl': {
        borderRadius: theme.radii['4xl'],
      },
      'md+lg': {
        borderRadius: theme.radii['md+lg'],
      },
    },
  });
};

const statusVariants = ({ theme }) => {
  return variant({
    prop: ThemeVariables.STATUS,
    variants: {
      primary: {},
      error: {},
      info: {},
    },
  });
};

const stateVariants = ({ appearance, status, theme }) => {
  return variant({
    prop: ThemeVariables.STATE,
    variants: {
      active: {},
      pressed: {
        backgroundColor:
          appearance === Appearance.FILLED
            ? theme.colors[status][300]
            : 'transparent',
        borderColor:
          appearance === Appearance.FILLED
            ? theme.colors[status][300]
            : appearance === Appearance.OUTLINE
            ? theme.colors[status][500]
            : 'transparent',
        opacity: appearance === Appearance.FILLED ? 1 : 0.5,
      },
      disabled:
        appearance === Appearance.FILLED
          ? {
              // opacity: 0.5,
              bg: theme.colors[status][50],
              borderColor: theme.colors[status][50],
            }
          : appearance === Appearance.OUTLINE
          ? {
              opacity: 0.4,
              // backgroundColor: 'transparent',
              // borderColor: theme.colors[status][50],
            }
          : {
              // bg: 'transparent',
              // color: theme.colors[status][100],
              opacity: 0.4,
            },
    },
  });
};

const StyledButton = styled(Pressable).attrs(
  ({ shadow, appearance, state, status, theme }) => ({
    pressStyle: {
      backgroundColor:
        appearance === Appearance.FILLED
          ? theme.colors[status][300]
          : 'transparent',
      borderColor:
        appearance === Appearance.FILLED
          ? theme.colors[status][300]
          : appearance === Appearance.OUTLINE
          ? theme.colors[status][500]
          : 'transparent',
      opacity: appearance === Appearance.FILLED ? 1 : 0.5,
    },
    shadow:
      appearance === Appearance.GHOST || state === State.DISABLED
        ? 'none'
        : shadow || 'md',
  }),
)`
  ${color}
  ${space}
  ${border}
  ${(props) => sizeVariants(props)}
  ${(props) => appearanceVariants(props)}
  ${(props) => roundedVariants(props)}
  ${(props) => statusVariants(props)}
  ${(props) => stateVariants(props)}
  ${({ shadow, theme }) =>
    shadow && shadow !== 'none' ? theme.boxShadows[shadow] : ''}
  ${({ shadow, theme }) => ({
    'shadow-color':
      shadow && shadow !== 'none'
        ? theme.colors.singletone.black
        : 'transparent',
  })}
`;

// ${(props) => categoryVariants(props)}

const Button = ({
  children = '',
  size = 'md',
  iconSize,
  state,
  appearance = Appearance.FILLED,
  iconLeft,
  iconRight,
  imgLeft,
  status = Status.PRIMARY,
  rounded = 'md',
  shadow = 'md',
  hideLeftIcon = false,
  loading = false,
  ...rest
}: ButtonPropTypes) => (
  <StyledButton
    {...rest}
    rounded={rounded}
    shadow={shadow}
    size={size}
    appearance={appearance}
    state={state}
    disabled={state === State.DISABLED}
    status={status}
  >
    <View
      flexDirection="row"
      justifyContent={loading ? 'center' : 'space-between'}
      alignItems="center"
    >
      {loading ? (
        <CustomLottieView
          autoPlay
          loop
          source={require('../../../../../assets/lottie/loading.json')}
          style={{ width: 56, height: 20 }}
          containerWidth={56}
        />
      ) : (
        <>
          {iconLeft && typeof iconLeft === 'string' ? (
            <View mr="sm">
              <Icon
                buttonSize={size}
                iconSize={iconSize}
                name={iconLeft}
                iconAppearance={appearance}
                state={state}
                status={status}
              />
            </View>
          ) : hideLeftIcon ? null : (
            <View />
          )}
          {imgLeft ? (
            <Image
              source={{
                uri: imgLeft,
              }}
              style={{
                height: 16,
                width: 16,
                borderRadius: radii.sm,
                resizeMode: 'contain',
              }}
            />
          ) : null}
          <Text
            category={TextCategories.BUTTON}
            textSize={size}
            appearance={appearance}
            selectable={false}
            numberOfLines={1}
            ellipsizemode="tail"
            state={state}
            status={status}
          >
            {typeof children === 'string'
              ? children?.replace(/(?:^|\s)\w/g, (char) => char.toUpperCase())
              : children}
          </Text>
          {iconRight && typeof iconRight === 'string' ? (
            <View ml={size === 'sm' ? 0 : 'sm'}>
              <Icon
                buttonSize={size}
                iconSize={iconSize}
                name={iconRight}
                iconAppearance={appearance}
                state={state}
                status={status}
              />
            </View>
          ) : (
            <View />
          )}
        </>
      )}
    </View>
  </StyledButton>
);

export default Button;
