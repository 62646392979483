import React, { forwardRef } from 'react';
import { SafeAreaView, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import {
  color,
  border,
  space,
  shadow,
  position,
  variant as variantStyle,
} from 'styled-system';
import baseTheme from '../../../../themes/new/theme';
import Pressable from '../../basic/Pressable/Pressable';

import View from '../../basic/View/View';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import Text from '../Typography/Text/Text';
import { InputStates } from './helpers';
import InputBase from './InputBase';
import { Appearance, Shape, State } from '../../../../themes/new/helper';

const variants = ({ theme }) => ({
  [InputStates.default]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.success]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    borderColor: 'success.500',
    borderRadius: 'md',
  },
  [InputStates.error]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    // borderColor: 'error.500',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.active]: {
    bg: 'primary.10',
    borderWidth: 'xs',
    color: 'primary.500',
    // border
    borderColor: 'primary.50',
    borderRadius: 'md',
    // shadows
    shadowColor: theme.colors.singletone.black,
    ...theme.boxShadows.md,
  },
  [InputStates.disabled]: {
    bg: 'grey.50',
    borderWidth: 'xs',
    color: 'primary.100',
    borderColor: 'primary.50',
    borderRadius: 'md',
  },
  [InputStates.unstyled]: {
    // bg: 'primary.10',
    borderWidth: '0px',
    borderColor: 'primary.50',
  },
});

const StyledInputView = styled(View)(
  {
    width: '100%',
    outlineWidth: '0px',
    // height: '40px',
    justifyContent: 'center',
    // paddingLeft: '20px',
    // paddingRight: '20px',
  },
  color,
  border,
  space,
  shadow,
  (props) => variantStyle({ variants: variants(props) }),
);

const SideButtonView = styled(View)({
  // position: 'absolute',
  // paddingTop: ({ theme }) => theme.space.lg,
  // paddingBottom: ({ theme }) => theme.space.lg,
  // paddingLeft: ({ theme }) => theme.space.lg,
  // paddingRight: ({ theme }) => theme.space.lg,
  // zIndex: 1,
  // cursor: 'pointer',
  // boxSizing: 'border-box',
});

const StyledPressabled = styled(Pressable)(
  {
    position: 'absolute',
    paddingTop: ({ theme }) => theme.space.lg,
    paddingBottom: ({ theme }) => theme.space.lg,
    paddingLeft: ({ theme }) => theme.space.lg,
    paddingRight: ({ theme }) => theme.space.lg,
    zIndex: 1,
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  position,
);

function InputWithIcon({
  leftIconName = null,
  rightIconName = null,
  rightTextName = '',
  state = InputStates.default,
  onLeftIconClick = () => {},
  onRightIconClick = () => {},
  inputRef,
  prefix = null,
  onPressMiddleArea,
  rightIconColor = 'primary.100',
  leftIconColor = 'primary.500',
  ...props
}) {
  const MiddleArea = () => (
    <View pt="xs" pb="xs">
      <InputBase
        ref={inputRef}
        state={
          state === InputStates.disabled
            ? InputStates.disabled
            : InputStates.unstyled
        }
        {...props}
      />
    </View>
  );
  return (
    <StyledInputView variant={state} display="flex" flexDirection="column">
      {!!leftIconName && (
        <View position="absolute" zIndex={1} left={0}>
          <IconButton
            name={leftIconName}
            size="md"
            iconColor={leftIconColor}
            appearance={Appearance.GHOST}
            shape={Shape.SQUARE}
            onPress={onLeftIconClick}
            withoutBorder
            state={State.ACTIVE}
          />
        </View>
      )}
      <View
        paddingLeft={leftIconName ? baseTheme.space['9xl'] : baseTheme.space.xl}
        paddingRight={
          rightIconName
            ? baseTheme.space['9xl']
            : rightTextName
            ? baseTheme.space['3xl+9xl']
            : baseTheme.space.xl
        }
        paddingTop={baseTheme.space.lg}
        paddingBottom={baseTheme.space.lg}
        flexDirection="row"
        alignItems="center"
      >
        {prefix}
        {onPressMiddleArea && typeof onPressMiddleArea === 'function' ? (
          <Pressable
            onPress={onPressMiddleArea}
            hitSlop={{ top: baseTheme.space.lg, bottom: baseTheme.space.lg }}
            style={{ flex: 1 }}
          >
            <View pointerEvents="none">{MiddleArea()}</View>
          </Pressable>
        ) : (
          <Pressable
            hitSlop={{ top: baseTheme.space.lg, bottom: baseTheme.space.lg }}
            onPress={() => inputRef?.current?.focus()}
            style={{ flex: 1 }}
          >
            {MiddleArea()}
          </Pressable>
        )}
      </View>
      {!!rightTextName && (
        <View position="absolute" zIndex={1} right={8}>
          <Pressable onPress={onRightIconClick}>
            <Text color={rightIconColor}>{rightTextName}</Text>
          </Pressable>
        </View>
      )}
      {!!rightIconName && (
        <View position="absolute" zIndex={1} right={0}>
          <IconButton
            name={rightIconName}
            size="md"
            iconColor={rightIconColor}
            appearance={Appearance.GHOST}
            shape={Shape.SQUARE}
            onPress={onRightIconClick}
            withoutBorder
            state={State.ACTIVE}
          />
        </View>
      )}
    </StyledInputView>
  );
}

export default InputWithIcon;
